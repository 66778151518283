// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.nav-menu,
%nav-menu {

  &__item {
    list-style-image: image-url('navigation', 'nav-menu/leaf.svg');
    list-style-type: square;

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('navigation', 'nav-menu/expanded.svg');
      list-style-type: circle;
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('navigation', 'nav-menu/collapsed.svg');
      list-style-type: disc;

      @include rtl() {
        list-style-image: image-url('navigation', 'nav-menu/collapsed-rtl.svg');
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }
}

//
// Drupal selectors.
//
#block-menu-block-1 {
  @include clearfix();
  width: 100%;
  float: right;

  .menu-block-wrapper {
    @include clearfix();
  }

  ul.menu {
    @include display-flex();
    @include justify-content('space-between');
    margin: 0;
    display: flex;
    justify-content: space-between;

    li {
      list-style: none;
      float: left;
      margin-right: 15px;
      white-space: nowrap;
    }

    li.last {
      margin: 0;
      margin-right: 0;
    }
  }
}

#block-menu-block-2 {
  @include clearfix();
  width: auto;
  float: right;
  margin-right: 3%;
  padding-right: 3%;
  border-right: 1px solid color('gold');

  .menu-block-wrapper {
    @include clearfix();
  }

  ul.menu {
    @include display-flex();
    @include justify-content('space-between');
    margin: 0;
    display: flex;
    justify-content: space-between;

    li {
      list-style: none;
      float: left;
      margin-right: 15px;
      white-space: nowrap;
    }

    li.last {
      margin: 0;
      margin-right: 0;
    }
  }
}

.menu {
  position: relative;
}

#block-system-user-menu {
  float: right;
  width: auto;

  .menu {
    padding: 0;
    margin: 0;
  }

  .menu__item.first {
    display: none;
  }
}

.menu__item {
  @extend %nav-menu__item;

  a,
  a:visited {
    color: color('gold');
    text-decoration: none;
    text-transform: uppercase;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}

// RESPONSIVE LAYOUT --------------------------------------------------------------
body .layout-center {

  @include respond-to(xxxs) {
    @include font-size(s);

    nav {
      padding: 20px 0;
    }

    .menu-container {

      .region-navigation {
        width: 100%;
        margin-top: 10px;
      }

      .today-date {
        width: 100%;
      }
    }

    #block-menu-block-1 ul.menu li {
      white-space: unset;
      text-align: center;
    }
  }

  @include respond-to(xxs) {
    @include font-size(m);

    .menu-container {
      .region-navigation {
        width: 100%;
      }

      .today-date {
        width: 100%;
      }
    }
  }

  @include respond-to(xs) {
    nav {
      @include font-size(xs);
    }
  }

  @include respond-to(s) {
    nav {
      @include font-size(s);
    }
  }

  @include respond-to(m) {
    nav {
      @include font-size(m);
    }
  }

 /*  @include respond-to(l) {}

  @include respond-to(xl) {}

  @include respond-to(xxl) {}

  @include respond-to(xxxl) {}
*/
}
