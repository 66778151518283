/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5;
  font-family: Helvetica, Arial, "Nimbus Sans L", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  background: #000;
}

@media print {
  html {
    font-size: 12pt;
  }
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  overflow: hidden;
  *zoom: 1;
  margin: 0;
  padding: 0;
  color: #000;
  overflow: unset;
}

body.front {
  background: #000;
}

.not-front .layout-center {
  background-color: #d0cfc9;
}

.messages--error {
  display: none;
}

.footer {
  width: 100%;
  margin: 50px auto 0;
  line-height: 1.3em;
  text-align: center;
  font-size: .85em;
  bottom: 0;
  left: 0;
  right: 0;
  background: #d0cfc9;
}

.footer .block-nodeblock {
  width: 70%;
  margin: 0 auto;
}

nav {
  background: #000;
  padding: 40px 0;
}

.not-logged-in nav {
  display: none;
}

.not-logged-in footer {
  padding: 40px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #cb9a2b;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #cb9a2b;
}

a:hover,
a:focus {
  position: relative;
}

a:active {
  color: #cb9a2b;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 1.625rem;
  line-height: 2;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #000;
  text-align: center;
}

h2 {
  font-size: 1.375rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.front h1 {
  color: #fff;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

cite {
  position: relative;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

del {
  position: relative;
}

dfn {
  font-style: italic;
}

i,
em {
  position: relative;
}

ins {
  position: relative;
}

mark {
  background-color: #fd0;
  color: #000;
}

q {
  position: relative;
}

s {
  position: relative;
}

small {
  font-size: 0.75rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

u {
  position: relative;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dt {
  position: relative;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

figcaption {
  position: relative;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

.button--variant-name {
  position: relative;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #d0cfc9;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #d0cfc9;
}

[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'], textarea {
  position: relative;
}

[type='range'] {
  position: relative;
}

[type='color'] {
  position: relative;
}

[type='file'] {
  position: relative;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

select {
  position: relative;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

thead {
  position: relative;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) and (max-width: 888px) {
  .layout-3col {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 0;
    padding-right: 0;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 0;
    padding-right: 0;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 0;
    padding-right: 0;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
}

@media (min-width: 999px) and (max-width: 1111px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  overflow: hidden;
  *zoom: 1;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 777px) and (max-width: 888px) {
  .layout-center {
    padding-left: 0;
    padding-right: 0;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) and (max-width: 666px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) and (max-width: 666px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #cb9a2b;
}

.clearfix,
.header,
.tabs {
  overflow: hidden;
  *zoom: 1;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header {
  background: #d0cfc9;
  text-align: center;
  margin-bottom: 0;
}

.header__logo {
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
  width: 15%;
  max-width: 175px;
  margin-bottom: 50px;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

#white-stripe {
  width: 100%;
  height: 5px;
  background: #fff;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 3rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

#block-menu-block-1 {
  overflow: hidden;
  *zoom: 1;
  width: 100%;
  float: right;
}

#block-menu-block-1 .menu-block-wrapper {
  overflow: hidden;
  *zoom: 1;
}

#block-menu-block-1 ul.menu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: "space-between";
  justify-content: "space-between";
  margin: 0;
  display: flex;
  justify-content: space-between;
}

#block-menu-block-1 ul.menu li {
  list-style: none;
  float: left;
  margin-right: 15px;
  white-space: nowrap;
}

#block-menu-block-1 ul.menu li.last {
  margin: 0;
  margin-right: 0;
}

#block-menu-block-2 {
  overflow: hidden;
  *zoom: 1;
  width: auto;
  float: right;
  margin-right: 3%;
  padding-right: 3%;
  border-right: 1px solid #cb9a2b;
}

#block-menu-block-2 .menu-block-wrapper {
  overflow: hidden;
  *zoom: 1;
}

#block-menu-block-2 ul.menu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: "space-between";
  justify-content: "space-between";
  margin: 0;
  display: flex;
  justify-content: space-between;
}

#block-menu-block-2 ul.menu li {
  list-style: none;
  float: left;
  margin-right: 15px;
  white-space: nowrap;
}

#block-menu-block-2 ul.menu li.last {
  margin: 0;
  margin-right: 0;
}

.menu {
  position: relative;
}

#block-system-user-menu {
  float: right;
  width: auto;
}

#block-system-user-menu .menu {
  padding: 0;
  margin: 0;
}

#block-system-user-menu .menu__item.first {
  display: none;
}

.menu__item a,
.menu__item a:visited {
  color: #cb9a2b;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}

.menu__item a:hover,
.menu__item a:visited:hover {
  text-decoration: underline;
}

body .layout-center {
  /*  @include respond-to(l) {}

  @include respond-to(xl) {}

  @include respond-to(xxl) {}

  @include respond-to(xxxl) {}
*/
}

@media (min-width: 0) and (max-width: 444px) {
  body .layout-center {
    font-size: 0.75rem;
  }
  body .layout-center nav {
    padding: 20px 0;
  }
  body .layout-center .menu-container .region-navigation {
    width: 100%;
    margin-top: 10px;
  }
  body .layout-center .menu-container .today-date {
    width: 100%;
  }
  body .layout-center #block-menu-block-1 ul.menu li {
    white-space: unset;
    text-align: center;
  }
}

@media (min-width: 444px) and (max-width: 555px) {
  body .layout-center {
    font-size: 0.875rem;
  }
  body .layout-center .menu-container .region-navigation {
    width: 100%;
  }
  body .layout-center .menu-container .today-date {
    width: 100%;
  }
}

@media (min-width: 555px) and (max-width: 666px) {
  body .layout-center nav {
    font-size: 0.625rem;
  }
}

@media (min-width: 666px) and (max-width: 777px) {
  body .layout-center nav {
    font-size: 0.75rem;
  }
}

@media (min-width: 777px) and (max-width: 888px) {
  body .layout-center nav {
    font-size: 0.875rem;
  }
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.menu-container {
  overflow: hidden;
  *zoom: 1;
}

.menu-container .today-date {
  color: #fff;
  float: left;
  z-index: 1;
  position: relative;
  margin-left: 6%;
  text-transform: uppercase;
}

.menu-container .region-navigation {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  padding-right: 5%;
  width: 70%;
  float: right;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.25;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #d0cfc9;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.75rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 4px solid #fff;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.front .layout-center {
  background-color: #000;
}

.front .layout-center footer {
  color: #fff;
}

.layout-center.confirmation {
  background-color: #000;
}

.layout-center.confirmation article {
  color: #fff;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.layout-center.confirmation article a,
.layout-center.confirmation article a:visited {
  color: #cb9a2b;
  text-decoration: none;
}

.layout-center.confirmation article a:hover {
  text-decoration: underline;
}

.layout-center.confirmation article p {
  margin-bottom: 50px;
}

.layout-center.confirmation article p a {
  margin-top: 40px;
  display: block;
}

.layout-center.confirmation footer {
  color: #fff;
}

.layout-3col form#editableviews-entity-form-pianos {
  border-radius: 0;
  width: 100%;
  padding: 0;
  background: #d0cfc9;
}

.view-pianos .attachment {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.view-pianos .attachment a,
.view-pianos .attachment a:visited {
  text-decoration: none;
}

.view-pianos .attachment a:hover {
  text-decoration: underline;
}

.view.view-pianos form {
  width: 95%;
}

.view.view-pianos .views-table thead th:last-child {
  border-top-right-radius: 0;
}

.view.view-pianos .views-table thead th:nth-child(1) {
  border-top-left-radius: 0;
}

.view.view-pianos th.views-field-field-contact-name,
.view.view-pianos td.views-field-field-contact-name {
  position: relative;
}

.view.view-pianos .views-row-edit-static {
  display: none;
}

.view-pianos {
  background: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.view-pianos .view-footer {
  padding: 0 5%;
}

.view-pianos .fg-toolbar.ui-corner-bl.ui-corner-br {
  position: absolute;
  bottom: -20px;
  right: 20px;
}

body .layout-center {
  /* @include respond-to(xxs) {
  }

  @include respond-to(xs) {

  }

  @include respond-to(s) {

  }

  @include respond-to(m) {

  }

  @include respond-to(l) {}

  @include respond-to(xl) {}

  @include respond-to(xxl) {}

  @include respond-to(xxxl) {}
*/
}

@media (min-width: 0) and (max-width: 444px) {
  body .layout-center .view-pianos {
    width: 95%;
  }
}

.form-item-feeds-FeedsCSVParser-delimiter,
.form-item-feeds-FeedsCSVParser-no-headers,
.form-item-feeds-FeedsCSVParser-encoding {
  display: none;
}

#piano-feeder-node-form > div {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

#piano-feeder-node-form #edit-feeds {
  border: 0;
  padding: 0;
}

#piano-feeder-node-form #edit-feeds legend {
  display: none;
}

.view {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.view .views-table {
  margin: 0 0 1.5rem;
}

.view .views-table thead th {
  background: #cb9a2b;
  color: #fff;
  font-weight: normal;
}

.view .views-table thead th:nth-child(1) {
  border-top-left-radius: 5px;
}

.view .views-table thead th:last-child {
  border-top-right-radius: 5px;
}

.view .views-table tbody tr {
  background: #fff;
}

.view .views-table th,
.view .views-table td {
  padding: 5px;
}

.view .views-table .views-field-field-pay-editable .form-type-checkboxes input {
  border-radius: 5px;
}

.view .views-table .field-name-field-pay label {
  display: none;
}

.vbo-views-form {
  padding: 15px 0;
}

.fg-button.ui-state-disabled {
  display: none;
}

#DataTables_Table_0 {
  margin-bottom: 10px;
}

.fg-toolbar.ui-corner-bl.ui-corner-br {
  overflow: hidden;
  *zoom: 1;
}

.fg-toolbar.ui-corner-bl.ui-corner-br #DataTables_Table_0_info {
  float: right;
}

.fg-toolbar.ui-corner-bl.ui-corner-br #DataTables_Table_0_paginate {
  position: relative;
}

.fg-toolbar.ui-corner-bl.ui-corner-br #DataTables_Table_0_paginate #DataTables_Table_0_previous {
  margin-right: 10px;
}

.fg-toolbar.ui-corner-bl.ui-corner-br #DataTables_Table_0_paginate #DataTables_Table_0_next {
  margin-left: 10px;
}

.view-display-id-select_pianos .fg-toolbar.ui-corner-tl.ui-corner-tr {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.view .attachment,
.fg-toolbar.ui-corner-tl.ui-corner-tr {
  background: #fff;
  padding: 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.view .attachment .view-display-id-customer,
.fg-toolbar.ui-corner-tl.ui-corner-tr .view-display-id-customer {
  width: 100%;
}

.view .attachment .view-display-id-customer .views-row,
.fg-toolbar.ui-corner-tl.ui-corner-tr .view-display-id-customer .views-row {
  position: relative;
}

.view .attachment .company-block,
.fg-toolbar.ui-corner-tl.ui-corner-tr .company-block {
  float: left;
  width: 60%;
}

.view .attachment .views-field-field-city,
.fg-toolbar.ui-corner-tl.ui-corner-tr .views-field-field-city {
  float: left;
  margin-right: 5px;
}

.view .attachment .field-content,
.fg-toolbar.ui-corner-tl.ui-corner-tr .field-content {
  line-height: 1em;
  display: block;
}

#DataTables_Table_0_length {
  float: right;
}

#DataTables_Table_0_filter input {
  margin-left: 10px;
}

.view tfoot {
  background: #f0e1bd;
}

.view tfoot .total-label {
  position: relative;
}

.view tfoot .total-value {
  position: relative;
}

.view table fieldset#edit-select {
  border: 1px solid #fff;
  padding: 0 20px 20px;
}

.view table fieldset#edit-select legend {
  background: #cb9a2b;
  border-radius: 5px;
}

.view table .vbo-select-all-markup {
  display: block;
  float: right;
  position: relative;
  top: -140px;
  right: 10px;
}

.view table .views-table {
  position: relative;
  top: -60px;
}

.view table .views-table caption {
  text-align: left;
  font-weight: bold;
  font-size: 1.5em;
}

.view table .view-footer {
  background: #fff;
  padding: 15px;
  margin: 0 0 1.5rem;
}

#auth-radio {
  width: 15px;
  height: 15px;
  background: url("../images/auth-radio.jpg");
  background-size: contain;
  float: left;
  margin-right: 5px;
}

.vbo-fieldset-select-all {
  text-align: center;
  width: 210px;
  padding: .6em 0;
  border: 0;
  position: absolute;
  top: 20px;
  right: 20px;
}

caption {
  text-align: left;
  font-weight: bold;
  font-size: 1.5em;
  margin: 30px 0 10px;
}

.page-user .tabs,
.page-node-edit .tabs,
.node-type-piano .tabs {
  width: 80%;
  margin: 0 auto;
}

.profile {
  overflow: hidden;
  *zoom: 1;
  width: 80%;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.profile .field {
  overflow: hidden;
  *zoom: 1;
}

.profile .field .field-label {
  float: left;
  width: 20%;
}

.profile .field .field-items {
  float: left;
  width: 75%;
}

.add-link {
  position: absolute;
  top: -30px;
  left: 10px;
  text-decoration: none;
}

.add-link:hover {
  position: absolute;
  text-decoration: underline;
}

.layout-3col form,
.node-piano.view-mode-full {
  position: relative;
  margin: 0 auto 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 0 10px 10px;
  border-radius: 10px;
  width: 80%;
}

#block-user-login {
  text-align: center;
  margin: 0 auto;
  max-width: 590px;
  width: 50%;
}

#block-user-login .block__title {
  color: #fff;
  margin-bottom: 0;
  font-weight: normal;
}

#block-user-login #user-login-form {
  display: inline-block;
  background: transparent;
  border: 0;
}

form #edit-submit,
form #edit-actions--2,
form #edit-submit--2,
form #edit-actions-submit,
form #edit-cancel,
form input[type='submit'],
form .active {
  background: #cb9a2b;
  padding: 0;
  height: 30px;
  display: block;
  border: 1px solid #fff;
  color: #fff;
  min-width: 200px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 2em;
  font-weight: lighter;
}

#edit-actions,
.button-wrapper {
  width: 100%;
  position: absolute;
  bottom: -65px;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
}

#user-cancel-confirm-form div {
  text-indent: -9999px;
}

#user-cancel-confirm-form div > * {
  display: none;
}

#user-cancel-confirm-form div #edit-actions {
  display: flex;
}

#user-cancel-confirm-form div #edit-actions input,
#user-cancel-confirm-form div #edit-actions #edit-cancel {
  display: block;
  text-indent: 0;
}

#user-login-form {
  width: 100%;
}

#user-login-form #edit-actions--2 {
  position: relative;
  width: 37%;
  margin: 0 auto;
}

#user-login-form #edit-actions--2 #edit-submit--2 {
  width: 100%;
}

#user-login-form .form-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

#user-login-form .form-item {
  width: 47%;
}

#user-login-form .form-item-name {
  margin-right: 6%;
  margin-top: 20px;
}

#user-login-form .form-item-pass {
  margin-top: 20px;
}

#user-login-form .form-item input {
  width: 100%;
  padding: 10px;
}

#user-login-form .item-list li {
  list-style: none;
}

#user-login-form .item-list li a,
#user-login-form .item-list li a:visited {
  font-size: .85em;
  text-decoration: none;
  color: #cb9a2b;
}

#user-login-form .item-list li a:hover {
  text-decoration: underline;
}

#user-login-form .item-list li.first {
  display: none;
}

#user-login-form .item-list ul {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 40px;
}

#piano-feeder-node-form > div {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

#piano-feeder-node-form #edit-actions {
  bottom: -65px;
}

#piano-feeder-node-form .vertical-tabs {
  display: none;
}

#views-form-pianos-all-pianos {
  position: relative;
}

#views-form-pianos-all-pianos #edit-submit--2 {
  bottom: -75px;
  width: 25%;
  position: absolute;
  margin-left: 35%;
}

#views-form-pianos-all-pianos .pay-icon {
  width: 15px;
  height: 15px;
  background: url("../images/auth-radio.jpg");
  background-size: auto auto;
  background-size: contain;
  margin: 0 auto;
}

#views-form-pianos-all-pianos .notpay-icon {
  width: 15px;
  height: 15px;
  background: url("../images/noauth-radio.jpg");
  background-size: auto auto;
  background-size: contain;
  margin: 0 auto;
}

#views-form-pianos-all-pianos #edit-select #edit-actionnode-assign-owner-action {
  margin-right: 20px;
  float: left;
  padding: 0 10px;
}

#views-form-pianos-all-pianos #edit-select .fieldset-wrapper {
  display: flex;
  justify-content: center;
}

#views-form-pianos-all-pianos #edit-select legend .fieldset-legend::before {
  content: 'Bulk ';
}

#editableviews-entity-form-pianos {
  border: 0;
}

#editableviews-entity-form-pianos #edit-actions #edit-actions-submit {
  padding: 0 10px;
}

/* #user-register-form {

  #edit-actions {

    #edit-submit {
      
    }
  }
} */
#bgefinancial-mailview-authorize-form {
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  margin-top: 10px;
  background: #d0cfc9;
}

#piano-node-form #edit-revision-information {
  display: none !important;
}

#piano-node-form #edit-author {
  display: block !important;
}

#piano-node-form #edit-author .form-item-date {
  display: none;
}

#piano-node-form .vertical-tabs {
  margin-left: 0;
  border: 0;
  overflow: visible;
}

#piano-node-form .vertical-tabs-list {
  display: none;
}

#user-profile-form #edit-mimemail {
  display: none;
}

#comment-form {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  overflow: visible;
}

input[disabled] {
  opacity: .5;
}

article.node-piano-feeder {
  overflow: visible;
}

.node-type-piano-feeder .tabs {
  margin-left: 10%;
}

.node-type-piano-feeder .submitted {
  position: absolute;
  top: 0;
  right: 10%;
}

.node-type-piano-feeder #comments {
  overflow: hidden;
  *zoom: 1;
  margin-top: 0;
  overflow: visible !important;
}

.node-type-piano-feeder #comments .comment__form-title {
  position: absolute;
  z-index: 2;
  right: 15%;
}

#autocomplete {
  z-index: 10000;
}
