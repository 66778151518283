.layout-3col form,
.node-piano.view-mode-full {
  position: relative;
  margin: 0 auto 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 0 10px 10px;
  border-radius: 10px;
  width: 80%;
}

#block-user-login {
  text-align: center;
  margin: 0 auto;
  max-width: 590px;
  width: 50%;

  .block__title {
    color: color('white');
    margin-bottom: 0;
    font-weight: normal;
  }

  #user-login-form {
    display: inline-block;
    background: transparent;
    border: 0;
  }
}

form {
  #edit-submit,
  #edit-actions--2,
  #edit-submit--2,
  #edit-actions-submit,
  #edit-cancel,
  input[type='submit'],
  .active {
    background: color('button-gold');
    padding: 0;
    height: 30px;
    display: block;
    border: 1px solid color('white');
    color: color('white');
    min-width: 200px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    line-height: 2em;
    font-weight: lighter;
  }
}

#edit-actions,
.button-wrapper {
  width: 100%;
  position: absolute;
  bottom: -65px;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
}

#user-cancel-confirm-form {

  div {
    text-indent: -9999px;

    > * {
      display: none;
    }
  
    #edit-actions {
      display: flex;
  
      input,
      #edit-cancel {
        display:  block;
        text-indent: 0;
      }
    }
  }
}

#user-login-form {
  width: 100%;

  #edit-actions--2 {
    position: relative;
    width: 37%;
    margin: 0 auto;

    #edit-submit--2 {
      width: 100%;
    }
  }

  .form-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .form-item {
    width: 47%;
  }

  .form-item-name {
    margin-right: 6%;
    margin-top: 20px;
  }

  .form-item-pass {
    margin-top: 20px;
  }

  .form-item input {
    width: 100%;
    padding: 10px;
  }

  .item-list {

    li {
      list-style: none;

      a,
      a:visited {
        font-size: .85em;
        text-decoration: none;
        color: color('link');
      }

      a:hover {
        text-decoration: underline;
      }
    }

    li.first {
      display: none;
    }
  }

  .item-list ul {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

#piano-feeder-node-form {

  > div {
    border: 1px solid #ccc;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
  }

  #edit-actions {
    bottom: -65px;
  }

  .vertical-tabs {
    display: none;
  }
}

#views-form-pianos-all-pianos {
  position: relative;

  #edit-submit--2 {
    bottom: -75px;
    width: 25%;
    position: absolute;
    margin-left: 35%;
  }

  .pay-icon {
    width: 15px;
    height: 15px;
    background: url('../images/auth-radio.jpg');
    background-size: auto auto;
    background-size: contain;
    margin: 0 auto;
  }

  .notpay-icon {
    width: 15px;
    height: 15px;
    background: url('../images/noauth-radio.jpg');
    background-size: auto auto;
    background-size: contain;
    margin: 0 auto;
  }

  #edit-select {

    #edit-actionnode-assign-owner-action {
      margin-right: 20px;
      float: left;
      padding: 0 10px;
    }

    .fieldset-wrapper {
      display: flex;
      justify-content: center;
    }

    legend .fieldset-legend::before {
      content: 'Bulk ';
    }
  }
}

#editableviews-entity-form-pianos {
  border: 0;

  #edit-actions {
   // width: 35%;

    #edit-actions-submit {
      padding: 0 10px;
    }
  }
}

/* #user-register-form {

  #edit-actions {

    #edit-submit {
      
    }
  }
} */

#bgefinancial-mailview-authorize-form {
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  margin-top: 10px;
  background: color('grey');
}

#piano-node-form {

  #edit-revision-information {
    display: none !important;
  }

  #edit-author {
    display: block !important;

    .form-item-date {
      display: none;
    }
  }

  .vertical-tabs {
    margin-left: 0;
    border: 0;
    overflow: visible;
  }

  .vertical-tabs-list {
    display: none;
  }
}

#user-profile-form {

  #edit-mimemail {
    display: none;
  }
}

#comment-form {
  @include clearfix();
  position: relative;
  overflow: visible;
}

input[disabled] {
  opacity: .5;
}

article.node-piano-feeder {
  overflow: visible;
}

.node-type-piano-feeder {

  .tabs{
    margin-left: 10%;
  }

  .submitted {
    position: absolute;
    top: 0;
    right: 10%;
  }

  #comments {
    @include clearfix();
    margin-top: 0;
    overflow: visible !important;

    .comment__form-title {
      position: absolute;
      z-index: 2;
      right: 15%;
    }
  }
}

#autocomplete {
  z-index: 10000;
}
