.form-item-feeds-FeedsCSVParser-delimiter,
.form-item-feeds-FeedsCSVParser-no-headers,
.form-item-feeds-FeedsCSVParser-encoding {
  display: none;
}

#piano-feeder-node-form {
  > div {
    border: 1px solid #ccc;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
  }

  #edit-feeds {
    border: 0;
    padding: 0;

    legend {
      display: none;
    }
  }
}
