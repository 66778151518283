// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  box-sizing: border-box;
}

html {
  @include define-type-sizing();
  // Change the default font family in all browsers (opinionated).
  @include typeface(body);
  @include define-type-sizing;
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  height: 100%;
  background: color('black');
}

body {
  @include clearfix();
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
  @include typey-debug-grid();
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  color: color(text);
  overflow: unset;
  //height: 100%;
}

body.front {
  background: color('black');
}

.not-front .layout-center {
  background-color: color('grey');
}

.messages--error {
  display: none;
}

.footer {
  width: 100%;
  margin: 50px auto 0;
  line-height: 1.3em;
  text-align: center;
  font-size: .85em;
  bottom: 0;
  left: 0;
  right: 0;
  background: color('header-grey');

  .block-nodeblock {
    width: 70%;
    margin: 0 auto;
  }
}

nav {
  background: color('black');
  padding: 40px 0;
}

.not-logged-in {
  nav {
    display: none;
  }

  footer {
    padding: 40px;
  }
}
