.view {
  width: 80%;
  margin: 0 auto;
  position: relative;

  .views-table {
    margin: 0 0 1.5rem;

    thead {

      th {
        background: color('table-head');
        color: color('white');
        font-weight: normal;
      }

      th:nth-child(1) {
        border-top-left-radius: 5px;
      }

      th:last-child {
        border-top-right-radius: 5px;
      }
    }

    tbody tr {
      background: color('white');
    }

    th,
    td {
      padding: 5px;
    }

    .views-field-field-pay-editable {

      .form-type-checkboxes {

        input {
          border-radius: 5px;
        }
      }
    }

    .field-name-field-pay {

      label {
        display: none;
      }
    }
  }
}

.vbo-views-form {
  padding: 15px 0;
}

.fg-button.ui-state-disabled {
  display: none;
}

#DataTables_Table_0 {
  margin-bottom: 10px;
}

.fg-toolbar.ui-corner-bl.ui-corner-br {
  @include clearfix();

  #DataTables_Table_0_info {
    float: right;
  }

  #DataTables_Table_0_paginate {
    position: relative;

    #DataTables_Table_0_previous {
      margin-right: 10px;
    }

    #DataTables_Table_0_next {
      margin-left: 10px;
    }
  }
}

.view-display-id-select_pianos {

  .fg-toolbar.ui-corner-tl.ui-corner-tr{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.view .attachment,
.fg-toolbar.ui-corner-tl.ui-corner-tr {
  background: color('white');
  padding: 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .view-display-id-customer {
    width: 100%;

    .views-row {
      position: relative;
    }
  }

  .company-block {
    float: left;
    width: 60%;
  }

  .views-field-field-city {
    float: left;
    margin-right: 5px;
  }

  .field-content {
    line-height: 1em;
    display: block;
  }
}

#DataTables_Table_0_length {
  float: right;
}

#DataTables_Table_0_filter {

  input {
    margin-left: 10px;
  }
}

.view tfoot {
  background: color('light-gold');

  .total-label {
    position: relative;
  }

  .total-value {
    position: relative;
  }
}

.view table {

  fieldset#edit-select {
    border: 1px solid color('white');
    padding: 0 20px 20px;

    legend {
      background: color('table-head');
      border-radius: 5px;
    }
  }

  .vbo-select-all-markup {
    display: block;
    float: right;
    position: relative;
    top: -140px;
    right: 10px;
  }

  .views-table {
    position: relative;
    top: -60px;

    caption {
      text-align: left;
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  .view-footer {
    background: color('white');
    padding: 15px;
    margin: 0 0 1.5rem;
  }
}

#auth-radio {
  width: 15px;
  height: 15px;
  background: url('../images/auth-radio.jpg');
  background-size: contain;
  float: left;
  margin-right: 5px;
}

.vbo-fieldset-select-all {
    text-align: center;
    width: 210px;
    padding: .6em 0;
    border: 0;
    position: absolute;
    top: 20px;
    right: 20px;
}

caption {
  text-align: left;
  font-weight: bold;
  font-size: 1.5em;
  margin: 30px 0 10px;
}

