.layout-3col form#editableviews-entity-form-pianos {
  border-radius: 0;
  width: 100%;
  padding: 0;
  background: color('grey');
}

.view-pianos .attachment {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  a,
  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.view.view-pianos {

  form {
    width: 95%;
  }

  .views-table thead th:last-child {
    border-top-right-radius: 0;
  }

  .views-table thead th:nth-child(1) {
    border-top-left-radius: 0;
  }

  th.views-field-field-contact-name,
  td.views-field-field-contact-name {
      position: relative;
  }

  .views-row-edit-static {
    display: none;
  }
}

.view-pianos {
  background: color('white');
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .view-footer {
    padding: 0 5%;
  }

  .fg-toolbar.ui-corner-bl.ui-corner-br {
    position: absolute;
    bottom: -20px;
    right: 20px;
  }
}

// RESPONSIVE LAYOUT --------------------------------------------------------------
body .layout-center {

  @include respond-to(xxxs) {
    .view-pianos {
      width: 95%;
    }
  }

  /* @include respond-to(xxs) {
  }

  @include respond-to(xs) {

  }

  @include respond-to(s) {

  }

  @include respond-to(m) {

  }

  @include respond-to(l) {}

  @include respond-to(xl) {}

  @include respond-to(xxl) {}

  @include respond-to(xxxl) {}
*/
}
