.page-user .tabs,
.page-node-edit .tabs,
.node-type-piano .tabs {
  width: 80%;
  margin: 0 auto;
}

.profile {
  @include clearfix();
  width: 80%;
  margin: 0 auto;
  background: color('white');
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .field {
    @include clearfix();

    .field-label {
      float: left;
      width: 20%;
    }

    .field-items {
      float: left;
      width: 75%;
    }
  }
}
