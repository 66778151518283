.layout-center.confirmation {
  background-color: color('black');

  article {
    color: color('white');
    text-align: center;
    width: 60%;
    margin: 0 auto;

    a,
    a:visited {
      color: color('link');
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    p {
      margin-bottom: 50px;

      a {
        margin-top: 40px;
        display: block;
      }
    }

  }

  footer {
    color: color('white');
  }
}
